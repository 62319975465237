import React from 'react';
import styled from 'styled-components';
import {BackgroundFigure} from '../shared/background-figure';
import ReactMarkdown from 'react-markdown';
import Slider from 'infinite-react-carousel';
import {device} from '../../models/devices';
import {Arrows} from '../shared/arrows';
import {Link} from 'gatsby';

const Container = styled.section`
  height: 540px;
  
  @media screen and ${device.tablet} {
    height: auto;
    margin-bottom: 104px;
  }
`;

const Label = styled.h2`
  color: #47b359;
  text-transform: uppercase;
  background-color: rgba(113, 192, 85, 0.32);
  font-size: 12px;
  font-weight: 500;
  padding: 4px 12px;
  border-radius: 3px;
  display: inline-block;
  margin-bottom: 10px;
`;

const Header = styled.span`
  font-size: 48px;
  font-weight: 500;
  color: #4f40ab;
  width: 270px;
  display: block;

  @media screen and ${device.tablet} {
    font-size: 32px;
    line-height: 1.5em;
    margin: 10px auto 30px;
  }
`;

const Wrapper = styled.div`
  padding-top: 120px;
  position: relative;
  display: flex;
  max-width: 1170px;
  margin: 0 auto;
  height: 100%;
  box-sizing: border-box;
  
  @media screen and ${device.tablet} {
    flex-direction: column;
    padding-top: 80px;
  }
`;

const HeaderContainer = styled.div`
  text-align: left;
  
  @media screen and ${device.tablet} {
    text-align: center;
    padding: 0 24px;
    margin-bottom: 56px;
  }
`;

const SolutionLabel = styled.span`
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 2.25px;
  color: #7b85a0;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 12px;
  &:after {
    content: '';
    display: inline-block;
    border-bottom: 1px solid currentColor;
    flex-grow: 1;
    margin-left: 9px;
  }
`;

const ProblemHeader = styled.span`
  text-transform: uppercase;
  padding: 5px 7px 4px 10px;
  border-radius: 3px;
  background-color: #f6f7fb;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 2.25px;
  color: #808384;
  display: inline-block;
  margin-bottom: 10px;
`;

const ProblemLink = styled(props => <Link {...props} />)`
  position: absolute;
  right: 24px;
  top: 24px;
  padding: 8px;
  border-radius: 50%;
  text-decoration: none;
  color: #7b85a0;
  line-height: 12px;

  &:hover {
    background-color: #F6F7FB;
    color: #157ee5;
  }
  
  @media screen and ${device.tablet} {
    top: 0
  }
`;

const Problem = styled.span`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.65;
  color: #7b85a0;
  display: block;
`;

const Solution = styled.span`
  font-size: 16px;
  line-height: 1.75;
  color: #7b85a0;
`;

const SliderWrapper = styled.div`
  width: 1220px;
  
  @media screen and ${device.tablet} {
    width: 300%;
  }
`;

const ItemContainer = styled.div`
  width: 370px !important;
  display: block;
  position: relative;
  text-decoration: none;
  padding: 24px 24px 31px;
  border-radius: 13px;
  border: 1px solid #e6ecfb;
  background-color: white;
  margin-right: 30px;
  margin-bottom: 40px;
  box-sizing: border-box;
  transition: box-shadow 0.5s linear, border-color 0.5s linear;
  ${SolutionLabel} { display: none; }
  ${Solution} { display: none; }
  
  .active & {
    box-shadow: 10px 12px 34px 0 rgba(128, 131, 132, 0.18);
    border-color: white;
    ${SolutionLabel} { display: flex; }
    ${Solution} { display: inline; }

    ${SolutionLabel} {
      color: #42b7ea
    }
    
    ${ProblemHeader} {
      background-color: #e1efff;
      color: #7b85a0;
    }
    
    ${Problem}, ${Solution} {
      color: #54595a;
    }
  }

  @media screen and ${device.tablet} {
    width: 100% !important;
    margin: 0 auto;
    padding: 0 32px;
    box-shadow: none;
    display: block !important;
    border: none;
    .active & {
      box-shadow: none;
      border: none;
    }
  }
`;

const SliderContainer = styled.div`
  flex: 1;
  overflow: hidden;
  &:after {
    content: '';
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 76%, rgba(255,255,255,1) 100%);
    position: absolute;
    top: 0;
    height: 100%;
    right: 0;
    width: 240px;
    z-index: 1;
  }
  .carousel-item {
    height: 420px;
  }
  
  @media screen and ${device.tablet} {
    &:after {
      content: none;
    }
    
    .carousel-item {
      height: auto;
    }
  }
`;

export const Challenges = (props: {
  title: string;
  label: string;
  items: Array<{
    problem: string;
    solution: string;
    url: string;
    position: number;
  }>
}): JSX.Element => {

  const sliderRef = React.createRef<{
    slickNext: () => void;
    slickPrev: () => void;
    slickGoTo: () => void;
    slickPause: () => void;
    slickPlay: () => void;
  }>();

  const changeSlide = (offset: number) => {
    // setActiveIndex(activeIndex + offset);
    if (offset > 0) {
      sliderRef.current?.slickNext();
    } else {
      sliderRef.current?.slickPrev();
    }
  };

  return (
    <Container>
      <Wrapper>
        <div className={'desktop-only'}>
          <BackgroundFigure
            type={'dots'}
            color={''}
            size={{width: 800, height: '100%'}}
            position={{top: 0}}
          />
        </div>
        <HeaderContainer>
          <Label><ReactMarkdown>{props.label}</ReactMarkdown></Label>
          <Header><ReactMarkdown>{props.title}</ReactMarkdown></Header>
          <Arrows onLeft={() => changeSlide(-1)} onRight={() => changeSlide(+1)} />
        </HeaderContainer>
        <SliderContainer>
          <SliderWrapper>
            <Slider
              ref={sliderRef}
              slidesToShow={3}
              arrows={false}
              virtualList={true}
              adaptiveHeight={true}
            >
              {
                props.items.map((item, index) => (
                  <ItemContainer key={index}>
                    <ProblemLink to={item.url}><span className="icon-export"/></ProblemLink>
                    <ProblemHeader>{index + 1}. problem</ProblemHeader>
                    <Problem><ReactMarkdown>{item.problem}</ReactMarkdown></Problem>
                    {item.solution && (
                      <React.Fragment>
                          <SolutionLabel>solution</SolutionLabel>
                          <Solution>
                              <ReactMarkdown>{item.solution}</ReactMarkdown>
                          </Solution>
                      </React.Fragment>
                    )}
                  </ItemContainer>
                ))
              }
            </Slider>
          </SliderWrapper>
        </SliderContainer>
      </Wrapper>
    </Container>
  );
};
