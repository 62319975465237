// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path='./index.d.ts'/>
import * as React from 'react';
import {graphql} from 'gatsby';
import {TitleSection} from '../components/home/title-section';
import './styles.scss';
import {MarkdownRemark} from '../models/markdown-remark';
import {PartnersList} from '../components/shared/partners';
import {UseCaseList} from '../components/home/usecases-list';
import {Integrations} from '../components/home/integrations';
import {Challenges} from '../components/home/challenges';
import {BackgroundFigure} from '../components/shared/background-figure';
import {BasePageData, BasePageFooter, BasePageHeader} from '../helpers/base-page';
import {useEffect, useState} from 'react';

interface HomePageData extends BasePageData {
  home: MarkdownRemark<{
    seoTitle: string;
    title: string;
    altText: string;
    subTitle: string;
    heroImage: string;
    inputPlaceholder: string;
    buttonText: string;
    subSections: Array<
      {
        type: 'integrationsSection',
        title: string;
        labelText: string;
        description: string;
        linkText: string;
        link: string;
      } | {
        type: 'partners';
        sectionTitle: string;
        partnerLogos: Array<{
          altText: string;
          logo: string;
        }>
      } | {
        type: 'useCaseSection';
        title: string;
        labelText: string;
        timerInterval: number;
      } | {
        type: 'challengeSection';
        title: string;
        labelText: string;
      }
    >
  }>;
  challenges: MarkdownRemark<{
    problem: string;
    solution: string;
    url: string;
    position: number;
  }>;
  integrationLogos: MarkdownRemark<{
    title: string;
    logo: string;
    position: number;
    backgroundColor: string;
  }>
}

const IndexPage = ({data}: {data: HomePageData}): JSX.Element => {

  const [isFormVisible, setFormVisibility] = useState(false);
  const [contactEmail, setContactEmail] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (window.location.hash === '#contact') {
      setFormVisibility(true);
    }
  }, []);

  return (
    <React.Fragment>
      <BasePageHeader
        siteTitle={data.home.edges[0].node.frontmatter.seoTitle ?? data.metaInfo.edges[0].node.frontmatter.siteTitle}
        siteDescription={data.metaInfo.edges[0].node.frontmatter.siteDescription}
        siteDescriptionTitle={data.metaInfo.edges[0].node.frontmatter.siteDescriptionTitle}
        siteImage={data.metaInfo.edges[0].node.frontmatter.siteImage}
        siteUrl={data.metaInfo.edges[0].node.frontmatter.siteUrl}
        logoLight={data.logos.edges[0].node.frontmatter.logoHeaderLight}
        logoDark={data.logos.edges[0].node.frontmatter.logoHeaderDark}
        logoShort={data.logos.edges[0].node.frontmatter.logoShort}
        links={data.navigation.edges[0].node.frontmatter.topCenterNavigation}
        modules={data.modules.edges.map(m => m.node.frontmatter)}
        usecases={data.usecases.edges.map(u => u.node.frontmatter)}
        hubspotFormId={data.hubspot.edges[0].node.frontmatter.hubSpotFormId}
        hubspotPortalId={data.hubspot.edges[0].node.frontmatter.hubSpotPortalId}
        contactFormConfig={data.contactFormConfig.edges[0].node.frontmatter}
        isFormVisible={isFormVisible}
        email={contactEmail}
        onFormClose={() => setFormVisibility(false)}
      />
      <TitleSection
        title={data.home.edges[0].node.frontmatter.title}
        altText={data.home.edges[0].node.frontmatter.altText}
        heroImage={data.home.edges[0].node.frontmatter.heroImage}
        subTitle={data.home.edges[0].node.frontmatter.subTitle}
        buttonText={data.home.edges[0].node.frontmatter.buttonText}
        inputPlaceholder={data.home.edges[0].node.frontmatter.inputPlaceholder}
        onSubmit={email => {setFormVisibility(true); setContactEmail(email);}}
      />
      <div className={'desktop-only'}>
        <BackgroundFigure
          type={'strips'}
          color={'#ffc02a'}
          size={{width: 86, height: 259}}
          position={{left: 0, top: 1117}}
        />
        <BackgroundFigure
          type={'strips'}
          color={'#ffc02a'}
          size={{width: 57, height: 203}}
          position={{right: 0, bottom: 1715}}
          style={{
            zIndex: 1,
            transform: 'scaleX(-1)'
          }}
        />
        <BackgroundFigure
          type={'circle'}
          color={'#ffc02a'}
          size={{width: 23, height: 46}}
          position={{right: 0, top: 1106}}
          style={{
            borderRadius: 23,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
        />
        <BackgroundFigure
          type={'triangle'}
          color={'#31ead2'}
          size={{width: 60, height: 60}}
          position={{left: 'calc(50% - 491px)', top: 1100}}
          style={{
            transform: `
              rotate(10deg)
              skewX(-30deg)
              scale(1,.866)
            `
          }}
        />
        <div style={{
          position: 'absolute',
          left: 0,
          bottom: 1550,
          width: 86,
          height: 155,
          overflow: 'hidden',
          zIndex: 0
        }}>
          <BackgroundFigure
            type={'triangle'}
            color={'#dee4fb'}
            size={{width: 60, height: 60}}
            position={{right: 30, bottom: 37}}
            style={{
              transform: `
                rotate(51deg)
                skewX(-30deg)
                scale(1,.866)
              `
            }}
          />
        </div>
        <div style={{
          position: 'absolute',
          right: 0,
          bottom: 1384,
          zIndex: 0,
          width: 36,
          height: 96,
          overflow: 'hidden'
        }}>
          <BackgroundFigure
            type={'triangle'}
            color={'#e1efff'}
            size={{width: 60, height: 60}}
            position={{left: 30}}
          />
        </div>
      </div>
      <div className={'mobile-only'}>
        <BackgroundFigure
          type={'strips'}
          color={'#ffc02a'}
          size={{width: 14, height: 48}}
          position={{right: 0, top: 2445}}
          style={{
            zIndex: 1,
            transform: 'scaleX(-1)'
          }}
        />
      </div>
      {
        data.home.edges[0].node.frontmatter.subSections.map((section, index) => {
          switch (section.type) {
            case 'partners': {
              return (
                <PartnersList
                  logos={section.partnerLogos}
                  key={index}
                  title={section.sectionTitle}
                />
              );
            }
            case 'challengeSection': {
              const challenges = data.challenges.edges.map(challenge => challenge.node.frontmatter);
              return (
                <Challenges
                  title={section.title}
                  label={section.labelText}
                  items={challenges}
                  key={index}
                />
              );
            }
            case 'useCaseSection': {
              const useCases = data.usecases.edges.map(i => i.node.frontmatter);
              return (
                <UseCaseList
                  key={index}
                  title={section.title}
                  label={section.labelText}
                  useCases={useCases}
                  timeInterval={section.timerInterval}
                />
              );
            }
            case 'integrationsSection': {
              const integrationLogos = data.integrationLogos.edges.map(edge => ({
                url: edge.node.frontmatter.logo,
                alt: edge.node.frontmatter.title,
                position: edge.node.frontmatter.position,
                color: edge.node.frontmatter.backgroundColor
              }));
              return (
                <Integrations
                  key={index}
                  title={section.title}
                  labelText={section.labelText}
                  description={section.description}
                  linkText={section.linkText}
                  link={section.link}
                  logos={integrationLogos}
                />
              );
            }
            default: {
              return '';
            }
          }
        })
      }
      <BasePageFooter
        feedbackTitle={data.contact.edges[0].node.frontmatter.title}
        feedbackLabel={data.contact.edges[0].node.frontmatter.subText}
        feedbackButtonText={data.contact.edges[0].node.frontmatter.buttonText}
        feedbackInputPlaceholder={data.contact.edges[0].node.frontmatter.inputPlaceholder}
        navigation={data.navigation}
        integrations={data.integrations}
        blogArticles={data.blogArticles}
        blogCategories={data.blogCategories}
        features={data.features.edges.map(f => f.node.frontmatter)}
        footerLogo={data.logos.edges[0].node.frontmatter.logoFooter}
      />
    </React.Fragment>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  {
    ...BaseData
    home: allMarkdownRemark(filter: {
      fileAbsolutePath: {
        regex: "/home.md$/"
      }
    }) {
      edges {
        node {
          frontmatter {
            seoTitle
            title
            altText
            subTitle
            heroImage
            inputPlaceholder
            buttonText
            subSections {
              type
              sectionTitle
              partnerLogos {
                altText
                logo
              }
              description
              title
              linkText
              link
              labelText
              timerInterval
            }
          }
        }
      }
    }
    challenges: allMarkdownRemark(filter: {
      fileAbsolutePath: {
        regex: "/challenges//"
      }
    }
    sort: { fields: [frontmatter___position], order: ASC }) {
      edges {
        node {
          frontmatter {
            problem
            solution
            url
            position
          }
        }
      }
    }
    integrationLogos: allMarkdownRemark(filter: {
      fileAbsolutePath: {
        regex: "/^(?!.*\/site\/).*integrations\/"
      }
    }) {
      edges {
        node {
          frontmatter {
            title
            position
            logo
            backgroundColor
          }
        }
      }
    }
  }
`;
