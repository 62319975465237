import React, {CSSProperties, useEffect, useState} from 'react';
import ReactMarkdown from 'react-markdown';
import Background from '../../images/featurebg.svg';
import PlayIcon from '../../images/play.svg';
import styled from 'styled-components';
import {Arrows} from '../shared/arrows';
import {UsecaseModel} from '../../models/usecase';

const breakpoint = '1220px';

const Container = styled.section`
  background-image: url(${Background});
  background-position: center;
  background-size: cover;
  text-align: center;
  padding-bottom: 120px;
  transition: background-image 0.3s linear;

  @media screen and (max-width: ${breakpoint}) {
    background-repeat: no-repeat;
    background-position: top center;
    background-size: ${breakpoint};
  }
`;

const Label = styled.h2`
  color: white;
  text-transform: uppercase;
  background-color: #67dffa;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 12px;
  border-radius: 3px;
  display: inline-block;
  margin-top: 120px;
  margin-bottom: 10px;
  
  @media screen and (max-width: ${breakpoint})  {
    margin-top: 80px;
  }
`;

const Title = styled.div`
  color: #4f40ab;
  font-size: 48px;
  font-weight: 500;
  max-width: 630px;
  text-align: center;
  margin: 0 auto 60px;
  
  @media screen and (max-width: ${breakpoint})  {
    font-size: 32px;
    line-height: 1.5em;
    padding: 0 24px;
    margin-top: 16px;
    margin-bottom: 15px;
  }
`;

const List = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-right: 30px;
  
  @media screen and (max-width: ${breakpoint}) {
    display: none;
  }
`;

const titleStyle: CSSProperties = {
  fontSize: 18,
  fontWeight: 500,
  color: '#4f40ab',
  cursor: 'pointer',
  verticalAlign: 'top',
  marginLeft: 16,
  display: 'inline-block'
};

const activeTitleStyle: CSSProperties = {
  color: '#00aaff',
  textDecoration: 'underline',
  cursor: 'initial'
};

const descriptionStyle: CSSProperties = {
  fontSize: 14,
  fontWeight: 'normal',
  lineHeight: 1.71,
  color: '#54595a',
  transition: 'all 0.3s linear'
};

const hiddenDescriptionStyle: CSSProperties = {
  height: 0,
  opacity: 0,
  overflow: 'hidden'
};

const slideStyle: CSSProperties = {
  padding: 24,
  borderRadius: 13,
  width: 370,
  textAlign: 'left',
  marginBottom: 19,
  transition: 'background-color 0.3s linear'
};

const LearnMoreLink = styled.a`
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  
  span {
    text-decoration: underline;
    color: #00aaff;
  }

  [class^="icon-"] {
    margin-left: 10px;
    font-size: 11px;
    color: #157ee5;
  }
`;

const MobileOnlyBlock = styled.div`
  @media screen and (min-width: ${breakpoint}) {
    display: none !important;
  }
`;

const activeContainerStyle: CSSProperties = {
  boxShadow: '8px 7px 14px 0 #d8ecf0',
  backgroundColor: 'white',
};

const Image = styled.img`
  width: 768px;
  
  @media screen and (max-width: ${breakpoint})  {
    width: calc(100% - 48px);
    margin: 0 auto;
  }
`;

export const UseCaseList = (props: {
  title: string;
  label: string;
  useCases: UsecaseModel[],
  timeInterval: number;
}): JSX.Element => {

  const [activeIndex, setIndex] = useState(0);
  let timer: number;

  const mobileSlider = React.createRef<HTMLDivElement>();

  useEffect(() => {
    timer = window.setInterval(() => {
      setIndex((activeIndex + 1) % props.useCases.length);
    }, props.timeInterval);

    return () => clearInterval(timer);
  });

  useEffect(() => {
    if (mobileSlider.current) {
      const bounds: DOMRect | undefined = mobileSlider.current.children.item(activeIndex + 1)?.getBoundingClientRect();
      mobileSlider.current.scrollLeft += (bounds?.left ?? 0) - (mobileSlider.current.getBoundingClientRect().width - (bounds?.width ?? 0)) / 2;
    }
  }, [activeIndex, mobileSlider]);

  const switchItem = (index: number) => {
    if (index !== activeIndex) {
      clearInterval(timer);
      setIndex(index);
    }
  };

  const changeSlide = (offset: number) => {
    let newIndex = (activeIndex + offset) % props.useCases.length;
    if (newIndex < 0) {
      newIndex += props.useCases.length;
    }
    setIndex(newIndex);
  };

  const setTimeInterval = (element: HTMLDivElement | null) => element?.style.setProperty('--timeInterval', `${props.timeInterval}ms`);

  return (
    <Container style={{
      backgroundImage: `url(${props.useCases[activeIndex].backgroundImage})`
    }}>
      <Label>
        <ReactMarkdown>{props.label}</ReactMarkdown>
      </Label>
      <Title>
        <ReactMarkdown>{props.title}</ReactMarkdown>
      </Title>

      <List>
        {
          props.useCases.map((useCase, index) => (
            <div
              style={{
                ...slideStyle,
                ...(index === activeIndex) && activeContainerStyle
              }}
              key={index}
            >
              <div ref={setTimeInterval} style={{...(index !== activeIndex) && {display: 'none'}}} className={'pie-loader'}>
                <div className="pie spinner"/>
                <div className="pie filler"/>
                <div className="mask"/>
              </div>
              <img
                style={{cursor: 'pointer', ...(index === activeIndex) && {display: 'none'}}}
                src={PlayIcon}
                alt=""
                width={26}
                height={26}
                onClick={() => switchItem(index)}
              />
              <span
                style={{
                  ...titleStyle,
                  ...(index === activeIndex) && activeTitleStyle
                }}
                onClick={() => switchItem(index)}
              >
                <ReactMarkdown>{useCase.title}</ReactMarkdown>
              </span>
              <div style={{
                ...descriptionStyle,
                ...(index !== activeIndex) && hiddenDescriptionStyle
              }}>
                <ReactMarkdown>{useCase.description}</ReactMarkdown>
                <br/>
                <LearnMoreLink href={useCase.link}>
                  <span>Learn More</span>
                  <i className={'icon-export'}/>
                </LearnMoreLink>
              </div>
            </div>
          ))
        }
      </List>
      <MobileOnlyBlock style={{marginBottom: 24}}>
        <Arrows onLeft={() => changeSlide(-1)} onRight={() => changeSlide(+1)} />
      </MobileOnlyBlock>
      <MobileOnlyBlock ref={mobileSlider} style={{display: 'flex', overflow: 'hidden', scrollBehavior: 'smooth', marginBottom: 32}}>
        <div style={{width: '50%', flexShrink: 0}}/>
        {props.useCases.map((useCase, index) => (
          <div
            key={index}
            style={{
              flexShrink: 0,
              padding: '12px 16px',
              borderRadius: 25,
              height: 50,
              boxSizing: 'border-box',
              background: index === activeIndex ? 'white' : 'none'
            }}
          >
            <div ref={setTimeInterval} style={{...(index !== activeIndex) && {display: 'none'}}} className={'pie-loader'}>
              <div className="pie spinner"/>
              <div className="pie filler"/>
              <div className="mask"/>
            </div>
            <img
              style={{cursor: 'pointer', ...(index === activeIndex) && {display: 'none'}}}
              src={PlayIcon}
              alt=""
              width={26}
              height={26}
              onClick={() => switchItem(index)}
            />
            <span style={{
              ...titleStyle,
              ...(index === activeIndex) && activeTitleStyle
            }} onClick={() => switchItem(index)}><ReactMarkdown>{useCase.title}</ReactMarkdown></span>
          </div>
        ))}
        <div style={{width: '50%', flexShrink: 0}}/>
      </MobileOnlyBlock>
      <MobileOnlyBlock style={{width: '100%', padding: '0 24px', fontSize: 18, lineHeight: 1.6, boxSizing: 'border-box', color: '#808384', marginBottom: 24}}>
        {props.useCases[activeIndex].description}
      </MobileOnlyBlock>
      <Image src={props.useCases[activeIndex].useCaseImage} alt=""/>
    </Container>
  );
};
