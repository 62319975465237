import React from 'react';
import Background from '../../images/mainbg.svg';
import HomeIcon1 from '../../images/home-icon-1.svg';
import HomeIcon2 from '../../images/home-icon-2.svg';
import HomeIcon3 from '../../images/home-icon-3.svg';
import styled from 'styled-components';
import {device} from '../../models/devices';
import ReactMarkdown from 'react-markdown';
import {EmailForm} from '../shared/email-form';
import {BackgroundFigure} from '../shared/background-figure';

const Container = styled.section`
  padding: 176px 24px 0;
  text-align: center;
  margin-bottom: 72px;
  background: url(${Background}) center bottom / cover no-repeat;
  
  @media screen and ${device.tablet} {
    padding-top: 114px;
    margin-bottom: 56px;
    background-size: cover;
  }
`;

const Title = styled.h1`
  color: white;
  font-weight: 500;
  font-size: 55px;
  line-height: 1.47;
  margin: 0 auto 22px;
  
  @media screen and ${device.tablet} {
    font-size: 32px;
    line-height: 1.43;
    margin-bottom: 24px;
  }
`;

const SubTitle = styled.div`
  color: #cfe7fc;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.78;
  margin-bottom: 48px;

  @media screen and ${device.tablet} {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 30px;
  }
`;

const MainImage = styled.img`
  max-width: 1047px;
  width: 100%;
  
  @media screen and ${device.tablet} {
    width: calc(100% + 48px);
    margin: 0 -24px;
    z-index: 1;
    position: relative;
  }
`;

const Icon1 = styled.img`
  position: absolute;
  top: -10px;
  right: -2px;
  
  @media screen and ${device.tablet} {
    width: 80px;
    top: -26px;
    right: -12px;
    z-index: 2;
  }
`;

const Icon2 = styled.img`
  position: absolute;
  top: 305px;
  left: -20px;
  
  @media screen and ${device.tablet} {
    width: 43px;
    top: 134px;
    left: -25px;
    z-index: 2;
  }
`;

const Icon3 = styled.img`
  position: absolute;
  bottom: 17px;
  right: -16px;
  
  @media screen and ${device.tablet} {
    width: 35px;
    bottom: 50px;
    right: -23px;
    z-index: 2;
  }
`;

export const TitleSection = (props: {
  title: string;
  altText: string;
  subTitle: string;
  heroImage: string;
  inputPlaceholder: string;
  buttonText: string;
  onSubmit?: (email: string) => void;
}): JSX.Element => {

  return (
    <Container>
      <Title><ReactMarkdown>{props.title}</ReactMarkdown></Title>
      <SubTitle><ReactMarkdown>{props.subTitle}</ReactMarkdown></SubTitle>
      <EmailForm
        inputPlaceholder={props.inputPlaceholder}
        buttonText={props.buttonText}
        onSubmit={email => props.onSubmit?.(email)}
        buttonColor={'green'}
        inputWidth={296}
      />
      <div style={{position: 'relative', margin: '0 auto', width: 'fit-content', marginTop: 66}}>
        <MainImage
          src={props.heroImage}
          alt={props.altText}
        />
        <Icon1 src={HomeIcon1} alt=""/>
        <Icon2 src={HomeIcon2} alt=""/>
        <Icon3 src={HomeIcon3} alt=""/>
        <div className={'mobile-only'}>
          <div style={{
            position: 'absolute',
            left: -15,
            bottom: 0,
            zIndex: 0,
            width: 50,
            height: 50,
            overflow: 'hidden',
            transform: 'rotate(190deg)'
          }}>
            <BackgroundFigure
              type={'triangle'}
              color={'#31EAD2'}
              size={{width: 30, height: 30}}
              position={{left: 10, top: 10}}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};
