import React, {CSSProperties} from 'react';
import Arrows from '../../images/arrows.svg';
import {IntegrationLogos} from './integration-logos';
import {IntegrationLogo} from '../../models/integration-logo';
import styled from 'styled-components';
import {device} from '../../models/devices';
import ReactMarkdown from 'react-markdown';

const containerStyle: CSSProperties = {
  textAlign: 'center'
};

const Header = styled.h2`
  color: #04aaff;
  text-transform: uppercase;
  background-color: #d2f0ff;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 12px;
  border-radius: 3px;
  display: inline-block;
  margin-top: 120px;
  margin-bottom: 10px;
  letter-spacing: 2px;

  @media screen and ${device.tablet} {
    padding: 4px 10px;
    margin-top: 80px;
  }
`;

const Title = styled.span`
  color: #4f40ab;
  font-size: 48px;
  font-weight: 500;
  text-align: center;
  margin: 0 auto 16px;
  
  @media screen and ${device.tablet} {
    font-size: 32px;
    line-height: 1.45em;
    margin-bottom: 25px;
    padding: 0 24px;
  }
`;

const Description = styled.div`
  font-size: 18px;
  line-height: 1.61;
  color: #808384;
  max-width: 500px;
  margin: 0 auto 24px;
  
  @media screen and ${device.tablet} {
    max-width: 250px;
    padding: 0 24px;
  }
`;

const Link = styled.a`
  font-size: 16px;
  font-weight: 500;
  color: #4886ff;
  
  i {
    background: url(${Arrows});
    display: inline-block;
    width: 15px;
    height: 11px;
    background-position: center;
    background-repeat: no-repeat;
    vertical-align: middle;
    margin-left: 8px
  }
  
  @media screen and ${device.tablet} {
    max-width: 250px;
    display: block;
    margin: 0 auto;
    
    i { display: none; }
  }
`;

export const Integrations = (props: {
  title: string;
  labelText: string;
  description: string;
  linkText: string;
  link: string;
  logos: IntegrationLogo[];
}): JSX.Element => {
  return (
    <section style={containerStyle}>
      <Header>
        <ReactMarkdown>{props.labelText}</ReactMarkdown>
      </Header>
      <Title>
        <ReactMarkdown>{props.title}</ReactMarkdown>
      </Title>
      <Description>
        <ReactMarkdown>{props.description}</ReactMarkdown>
      </Description>
      <Link href={props.link}>
        {props.linkText}
        <i/>
      </Link>
      <IntegrationLogos logos={props.logos}/>
    </section>
  );
};
