import React, {CSSProperties} from 'react';
import {IntegrationLogo} from '../../models/integration-logo';
import Lines from '../../images/lines.svg';
import styled from 'styled-components';
import {Squircle} from '../../helpers/squircle';
import {device} from '../../models/devices';

const logoConfig: Array<{
  [screen in 'desktop' | 'mobile']: {
    position: {
      top: number;
      left: number;
    };
    size: {
      width: number;
    }
  };
}> = [
  {
    desktop: {
      position: {
        top: 491,
        left: 1025
      },
      size: {
        width: 40,
      }
    },
    mobile: {
      position: {
        top: 736,
        left: 252
      },
      size: {
        width: 54,
      }
    },
  },
  {
    desktop: {
      position: {
        top: 463,
        left: 124
      },
      size: {
        width: 62,
      }
    },
    mobile: {
      position: {
        top: 335,
        left: 78
      },
      size: {
        width: 60,
      }
    }
  },
  {
    desktop: {
      position: {
        top: 179,
        left: 896
      },
      size: {
        width: 79,
      }
    },
    mobile: {
      position: {
        top: 452,
        left: 219
      },
      size: {
        width: 89,
      }
    }
  },
  {
    desktop: {
      position: {
        top: 563,
        left: 469
      },
      size: {
        width: 57,
      }
    },
    mobile: {
      position: {
        top: 478,
        left: 83
      },
      size: {
        width: 67,
      }
    }
  },
  {
    desktop: {
      position: {
        top: 522,
        left: 787
      },
      size: {
        width: 40,
      }
    },
    mobile: {
      position: {
        top: 885,
        left: 270
      },
      size: {
        width: 40,
      }
    }
  },
  {
    desktop: {
      position: {
        top: 382,
        left: 700
      },
      size: {
        width: 79,
      }
    },
    mobile: {
      position: {
        top: 791,
        left: 91
      },
      size: {
        width: 89,
      }
    }
  },
  {
    desktop: {
      position: {
        top: 248,
        left: 120
      },
      size: {
        width: 74,
      }
    },
    mobile: {
      position: {
        top: 121,
        left: 107
      },
      size: {
        width: 84,
      }
    }
  },
  {
    desktop: {
      position: {
        top: 211,
        left: 568
      },
      size: {
        width: 62,
      }
    },
    mobile: {
      position: {
        top: 288,
        left: 224
      },
      size: {
        width: 71,
      }
    }
  },
  {
    desktop: {
      position: {
        top: 153,
        left: 305
      },
      size: {
        width: 39,
      }
    },
    mobile: {
      position: {
        top: 184,
        left: 264
      },
      size: {
        width: 39,
      }
    }
  },
  {
    desktop: {
      position: {
        top: 321,
        left: 1067
      },
      size: {
        width: 61,
      }
    },
    mobile: {
      position: {
        top: 607,
        left: 235
      },
      size: {
        width: 72,
      }
    }
  },
  {
    desktop: {
      position: {
        top: 342,
        left: 370
      },
      size: {
        width: 90,
      }
    },
    mobile: {
      position: {
        top: 636,
        left: 102
      },
      size: {
        width: 87,
      }
    }
  }
];

const Wrapper = styled.div`
  width: 1170px;
  margin: 0 auto;
  position: relative;
  height: 780px;
  
  @media screen and ${device.tablet} {
    width: 320px;
    height: 990px;
  }
`;

const Item = styled.div<{
  angle: number;
  desktopWidth: number;
  desktopHeight: number;
  desktopTop: number;
  desktopLeft: number;
  mobileWidth: number;
  mobileHeight: number;
  mobileTop: number;
  mobileLeft: number;
  color: string;
}>`
  position: absolute;
  top: ${props => props.desktopTop}px;
  left: ${props => props.desktopLeft}px;
  filter: drop-shadow(4px 10px 12px rgba(0, 0, 0, 0.15));

  &:before {
    width: ${props => 1.6*props.desktopWidth}px;
    height: ${props => 1.6*props.desktopHeight}px;
    content: "";
    position: absolute;
    top: 0;
    left: 0; 
    background-image: url(${props => Squircle(props.color)});
    background-repeat: no-repeat;
    background-position: center;
    transform: translate(-50%, -50%) rotate(${props => props.angle}deg);
    background-size: contain;
  }

  @media screen and ${device.tablet} {
    top: ${props => props.mobileTop}px;
    left: ${props => props.mobileLeft}px;
    &:before {
      width: ${props => 1.6*props.mobileWidth}px;
      height: ${props => 1.6*props.mobileHeight}px;
    }
  }
`;

const logoStyle: CSSProperties = {
  position: 'absolute',
  zIndex: 2,
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)'
};

export const IntegrationLogos = (props: {logos: IntegrationLogo[]}): JSX.Element => {
  return (
    <div style={{
      backgroundImage: `url(${Lines})`,
      backgroundSize: 'auto',
      backgroundPositionX: 'center',
      backgroundPositionY: 'top',
    }}>
      <Wrapper>
        {props.logos.map((logo, index) => (
          <Item
            key={index}
            angle={Math.random()*360}
            desktopTop={logoConfig[logo.position]?.desktop.position.top}
            desktopLeft={logoConfig[logo.position]?.desktop.position.left}
            desktopWidth={logoConfig[logo.position]?.desktop.size.width}
            desktopHeight={logoConfig[logo.position]?.desktop.size.width}
            mobileTop={logoConfig[logo.position]?.mobile.position.top}
            mobileLeft={logoConfig[logo.position]?.mobile.position.left}
            mobileWidth={logoConfig[logo.position]?.mobile.size.width}
            mobileHeight={logoConfig[logo.position]?.mobile.size.width}
            color={logo.color ?? '#FFF'}
          >
            <img
              src={logo.url}
              alt={logo.alt}
              style={{
                ...logoStyle,
                width: logoConfig[logo.position]?.mobile.size.width,
              }}
            />
          </Item>
        ))}
      </Wrapper>
    </div>
  );
};
